.summary-logo {
  &-canadapost,
  &-fedex,
  &-dhl,
  &-purolator {
    margin: -2rem auto;
    width: 130px;
  }

  &-fedex-ground,
  &-fedex-express {
    margin: 0rem auto;
    width: 100px;
  }

  &-shift {
    margin: 0.5rem auto;
    width: 80px;
  }

  &-loomisexpress {
    margin: 1rem auto;
    width: 180px;
  }
  &-eeko,
  &-canpar,
  &-nationex,
  &-freightgls,
  &-gls,
  &-intelcom,
  &-spring,
  &-rivo,
  &-trexity,
  &-delivro,
  &-ulala {
    margin: 1rem auto;
    width: 100px;
  }

  &-icscourier,
  &-canadapost3pl {
    margin: 1rem auto;
    width: 120px;
  }

  &-uniuni,
  &-livrapide {
    width: 130px;
    margin: 1.5rem auto;
  }

  &-freightaircanada {
    width: 130px;
    margin: 0.5rem auto;
  }

  &-dayross {
    width: 50px;
    margin: 0.5rem auto;
  }

  &-mdx,
  &-ups,
  &-flashbox {
    width: 110px;
    margin: 0rem auto;
  }

  &-chasseurscourrier,
  &-eva {
    width: 43px;
    margin: 1rem auto;
  }

  &-courantplus {
    width: 130px;
    margin: 1rem auto;
  }

  // For multi-leg AirCanada services
  &-ac_sp,
  &-ac_ic,
  &-ac_cp,
  &-ac_un,
  &-ac_tf {
    margin: 1rem auto;
    width: 100px;
    height: 44px;
  }
}

.third-party-summary {
  font-size: 0.8rem;
}

.third-party-insurance-row {
  padding-left: 24px;

  .cost-summary-rows {
    background: #F7FFFE;
    border-top: 1px solid #00BFA5;
    border-bottom: 1px solid #00BFA5;
    padding-bottom: 6px;
    padding-top: 6px;
  }

  .third-party-option-header {
    margin-left: -24px;
  }
}

.cost-summary-labels {
  text-transform: capitalize;
}
